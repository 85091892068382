import { createBrowserHistory } from 'history';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import PageRouter from './PageRouter';

const history = createBrowserHistory();

const App = () => {
  const [sites, setSites] = React.useState<any[]>([]);

  React.useEffect(() => {
    async function fetchSites() {
      const sitesResponse = await fetch(`${process.env.MILWAUKEE_API_DOMAIN}/api/packout/get-sites`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });

      const { sites } = await sitesResponse.json();

      setSites(sites);
    }

    fetchSites();
  }, []);

  if (!sites || sites.length === 0) {
    return null;
  }

  return (
    <Router history={history}>
      <Switch>
        {sites &&
          sites.map((x) => {
            const url = x.cultureAlias ? x.cultureAlias.toLowerCase() : x.cultureCode.toLowerCase();

            return (
              <Route key={url} path={`/${url}/`}>
                <PageRouter site={x} baseUrl={url} cultureCode={x.cultureCode} />
              </Route>
            );
          })}

        <Redirect to={'/eu/'}></Redirect>
      </Switch>
    </Router>
  );
};

export default App;
